@import 'Assets/styles/global.scss';
.ant-card-head {
    border-bottom: 0 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    &-title {
        font-size: 16px;
        font-weight: 700;
        color: $black-color;
        margin: 30px 0px 20px 0px;
        padding: 0;
        @media (max-width: 1366px) {
            font-size: 14px;
        }
    }
}
.cal-modal {
    width: 365px !important;
    padding-bottom: 0;
    .ant-modal-header {
        padding-top: 30px;
        padding-bottom: 10px;
        border-radius: 20px;
        border-bottom: 0;
        .ant-modal-title {
            text-align: center !important;
            font-size: 16px;
            color: $black-color;
            font-weight: 600;
            width: 90%;
            margin: 0 auto;
            @media (min-width: 1440px) {
                font-size: 18px;
            }
            @media (min-width: 1920px) {
                font-size: 20px;
            }
        }
    }
    .ant-modal-close {
        top: 15px !important;
        svg {
            width: 18px;
            height: 18px;
            color: $black-color;
        }
    }

    .ant-modal-body {
        padding: 20px 0px 20px 0px;
        .ant-form {
            // padding: 0px 20px 0px 20px;
            .input-sm {
                input {
                    border: 0;
                    padding-left: 0;
                    border-bottom: 1px solid #e9e9e9 !important;
                    font-size: 14px;
                    color: $text-sec-color;
                    ::placeholder {
                        color: $text-sec-color;
                    }
                    &:hover,
                    &:focus {
                        box-shadow: none !important;
                    }
                    @media (min-width: 1440px) {
                        font-size: 15px;
                    }
                    @media (min-width: 1920px) {
                        font-size: 16px;
                    }
                }
            }
            .select-sm {
                .ant-select-selector {
                    border-radius: 3px;
                    border: 0;
                    box-shadow: 0px 6px 15px #0000000d;
                    font-size: 12px;
                    height: 40px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    color: $primary-color;
                    @media (min-width: 1440px) {
                        font-size: 13px;
                    }
                    @media (min-width: 1920px) {
                        font-size: 14px;
                    }
                }
                .ant-select-arrow,
                .ant-select-selection-placeholder {
                    color: $primary-color;
                }
            }
            .input-col {
                .ant-form-item-label {
                    position: relative;
                    width: 50%;
                    display: flex;
                    padding-left: 0;
                    color: $light-grey;
                    font-size: 14px;
                    @media (min-width: 1440px) {
                        font-size: 15px;
                    }
                    @media (min-width: 1920px) {
                        font-size: 16px;
                    }
                }
                input {
                    border: 0;
                    border-bottom: 1px solid #e9e9e9 !important;
                    font-size: 12px;
                    color: $text-sec-color;
                    padding-left: 0;
                    ::placeholder {
                        color: $text-sec-color;
                    }
                    &:hover,
                    &:focus {
                        box-shadow: none !important;
                    }
                    @media (min-width: 1440px) {
                        font-size: 13px;
                    }
                    @media (min-width: 1920px) {
                        font-size: 14px;
                    }
                }
            }
            .icon-input input,
            .time-picker .ant-picker-input input {
                border-bottom: 0 !important;
            }

            .full-btn {
                width: 100%;
                border: 0;
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 10px !important;
                height: 40px;
                border-radius: 5px;
                @media (min-width: 1440px) {
                    font-size: 13px;
                    height: 42px;
                }
                @media (min-width: 1920px) {
                    font-size: 14px;
                    height: 44px;
                }
            }

            .grey-btn {
                width: 100%;
                border: 0;
                font-size: 12px;
                font-weight: 600;
                background-color: $light-grey;
                color: #fff;
                margin-bottom: 10px !important;
                height: 40px;
                border-radius: 5px;
                @media (min-width: 1440px) {
                    font-size: 13px;
                    height: 42px;
                }
                @media (min-width: 1920px) {
                    font-size: 14px;
                    height: 44px;
                }
            }

            .ant-form-item {
                margin-bottom: 20px !important;
                &:last-child {
                    margin-bottom: 0 !important;
                }
                &.calendar-btn {
                    margin-bottom: 0px !important;
                }
            }
        }
        .schedule-form .icon-input input {
            border-bottom: 0 !important;
            font-size: 12px !important;
        }
        .schedule-form .item-wrap .input-sm {
            margin-bottom: 10px !important;
            width: 50%;
        }
        .schedule-form .btn-reset {
            margin-bottom: 0 !important;
            border-bottom: 0 !important;
            button {
                border-bottom: 0 !important;
                margin-bottom: 0 !important;
            }
        }
        .schedule-form .btn-row .ant-form-item {
            margin-bottom: 0 !important;
        }
        .scroll-wrap,
        .btn-scroll-wrap {
            padding: 0px 20px 0px 20px;
        }
        .mb-0 {
            margin-bottom: 0px !important;
        }
        .scroll-wrap {
            max-height: 275px;
            overflow-x: auto;
            scrollbar-color: #f5f5f5 !important;
            scrollbar-width: thin !important;
            &::-webkit-scrollbar {
                width: 5px;
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 6px rgba(116, 116, 116, 0.3);
                background-color: #ccc;
            }
        }
        .enhance-height {
            max-height: 380px;
        }
        .modal-height {
            max-height: 400px;
        }
        .invite-form {
            .sm-textarea {
                margin-bottom: 10px !important;
            }
            .btn-accept {
                margin-bottom: 0 !important;
                .full-btn {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
    .time-picker {
        .ant-form-item-control-input-content {
            border-bottom: 1px solid $grey-border;
        }

        .ant-picker {
            width: 100%;
            border: 0 !important;
            padding-left: 0 !important;
            &-focused {
                border: 0 !important;
                box-shadow: none !important;
            }
            &-input {
                input {
                    border-bottom: 0 !important;
                }
                .ant-picker-suffix {
                    color: $primary-color;
                }
            }
        }
    }

    @media (min-width: 1440px) {
        width: 420px !important;
    }
}
.sc-modal {
    .ant-modal-body {
        padding-top: 10px !important;
    }
}

.ant-picker-panel-container {
    border-radius: 5px;
    .ant-picker-panel {
        .ant-picker-footer {
            display: block !important;
            .ant-picker-now {
                display: none;
            }
            .ant-picker-ok {
                .ant-btn {
                    @include primary-gradient;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    border: 0;
                    border-radius: 3px;
                    width: 50px;
                }
            }
        }
    }
}
